// shared.module.ts
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { CarouselModule, SidebarModule } from '@syncfusion/ej2-angular-navigations';
import { EditService, ExcelExportService, FilterService, GridModule, GroupService, PagerModule, PageService, SearchService, SortService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { DropDownListModule, AutoCompleteModule, MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { NumericTextBoxModule, TextBoxModule, MaskedTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NumericDirective } from '../_helpers/directives/numeric.directive';
import { SafePipe } from '../_helpers/pipes.component';
import { FooterComponent } from '../components/footer/footer.component';
import { ForgetPasswordComponent } from '../components/forget-password/forget-password.component';
import { HeaderComponent } from '../components/header/header.component';
import { IndividualSignupComponent } from '../components/individual-signup/individual-signup.component';
import { ResetPasswordComponent } from '../components/reset-password/reset-password.component';
import { SigninComponent } from '../components/signin/signin.component';
import { SignupVerificationComponent } from '../components/signup-verification/signup-verification.component';

import { AboutComponent } from '../pages/about/about.component';
import { DataDeletionPolicyComponent } from '../pages/data-deletion-policy/data-deletion-policy.component';
import { PrivacyPolicyComponent } from '../pages/privacy-policy/privacy-policy.component';
import { RouterModule, UrlSerializer } from '@angular/router';
import { urlSerializer } from '../_helpers/url-serializer';
import { TermsOfServiceComponent } from '../pages/terms-of-service/terms-of-service.component';
import { AccountService } from '../services/account.service';
import { AuthenticationService } from '../services/authentication.service';
import { CampaignService } from '../services/campaign.service';
import { FacebookService } from '../services/facebook.service';
import { HandleErrors, ToastMessage } from '../_helpers/commonfunction';
import { NewUiHeaderComponent } from '../pages/newui-components/new-ui-header/new-ui-header.component';
import { NewUiFooterComponent } from '../pages/newui-components/new-ui-footer/new-ui-footer.component';


const SDkMODULES = [
  SidebarModule,
  ButtonModule,
  CarouselModule,
  GridModule,
  PagerModule,
  DropDownListModule,
  AutoCompleteModule,
  MultiSelectAllModule,
  FontAwesomeModule,
  TooltipModule,
  NumericTextBoxModule,
  TextBoxModule,
  MaskedTextBoxModule,
  NgbModule,
  SlickCarouselModule
];


const MODULES = [
  CommonModule,
  HttpClientModule,
  FormsModule,
  ReactiveFormsModule,
  ...SDkMODULES
];

const COMPOMENTS = [
  // SafePipe,
  NumericDirective,
  HeaderComponent,
  FooterComponent,
  AboutComponent,
  DataDeletionPolicyComponent,
  TermsOfServiceComponent,
  PrivacyPolicyComponent,
  NewUiHeaderComponent,
  NewUiFooterComponent
];


@NgModule({
  declarations: [
    COMPOMENTS
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MODULES,
    NgxSpinnerModule,
  ],
  exports: [
    COMPOMENTS,
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MODULES,
    NgxSpinnerModule
    
  ],

})
/*export class SharedRootModule { }*/


export class SharedRootModule {
  static forRoot(): ModuleWithProviders<SharedRootModule> {
    return {
      ngModule: SharedRootModule,
      providers: [CampaignService, FacebookService, PageService, SortService, FilterService, GroupService, EditService, ExcelExportService, SearchService, ToolbarService, ToastMessage, HandleErrors, AuthenticationService, AccountService,{ provide: UrlSerializer, useClass: urlSerializer }]
      
    };
  }
}
