import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { faBars, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-new-ui-header',
  templateUrl: './new-ui-header.component.html',
  styleUrls: ['./new-ui-header.component.css']
})
export class NewUiHeaderComponent implements OnInit, OnDestroy {
  fatchedParams: any;
  sub: any
  qrId: any;
  homeActive: boolean = false
  aboutActive: boolean = false
  campaignsData: any;
  storeId: any;
  campaign: any;
  faBars = faBars;
  showMyAccountMenu: boolean = false;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;

  @ViewChild('sidebar') sidebar?: SidebarComponent;
  public closeOnDocumentClick: boolean = true;
  public type: string = 'Over';
  public target: string = '.content';
  public enablePersistence: boolean = true;
  currentUrl = "";
  campaignDataEventSubscription: any;
  public onCreated(args: any) {

    (this.sidebar as SidebarComponent).element.style.visibility = '';
  }
  btnClick() {
    (this.sidebar as SidebarComponent).position = "Right";
    this.sidebar?.show();
  }
  closeClick() {
    this.sidebar?.hide();
  }


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private campaignService: CampaignService, public common: CommonService, public authService: AuthenticationService
  ) {

    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe((event: any) => {
        this.currentUrl = event?.url
        if (!this.campaignsData) {
          if (!this.currentUrl?.split('/')[1]?.startsWith('home')
           // && !this.currentUrl?.split('/')[1]?.startsWith('campaign')
          //  && !this.currentUrl?.split('/')[1]?.startsWith('giveanywhere')
            
            
            
            
          ) {
            // call dashboard info
            this.getDashboardInfo();
          }
        }


      });
    this.activatedRoute.queryParams.subscribe(params => {
      this.fatchedParams = params;

      //if (this.fatchedParams.organizationid) {
      //  this.getDashboardInfo();
      //}
      //else {
      //  this.common.setUpNavigationMenu(this.campaignsData, "", this.fatchedParams);
      //}
    });



  }

  ngOnInit(): void {


    this.campaignDataEventSubscription = this.common.getCampaignInfoEventTrigger().subscribe((data: any) => {
      if (!data?.isLoading && data.data) {
        this.campaignsData = data.data;
        this.common.setUpNavigationMenu(data.data, "", this.fatchedParams);
      }
    })
  }
  ngOnDestroy(): void {
    this.campaignDataEventSubscription?.unsubscribe();
  }


  getDashboardInfo() {
    if (this.fatchedParams.organizationid) {
      this.common.emitCampaignInfo({ isLoading: true, data: null })

      this.campaignService.getCampaigns(this.fatchedParams.organizationid, false).subscribe(res => {
        this.campaignsData = res;
        this.common.emitCampaignInfo({ isLoading: false, data: res })
        this.common.setUpNavigationMenu(this.campaignsData, "", this.fatchedParams);
      });
    }
  }

  getIndividualDashboardInfo() {
    this.campaignService.getIndividualCampaigns(this.fatchedParams.organizationid, false).subscribe(res => {
      this.campaignsData = res;

      this.common.setUpNavigationMenu(this.campaignsData, "", this.fatchedParams);
    });
  }

  showHomeBgcolor() {
    // this.homeActive = true
    // this.aboutActive = false
  }
  showAboutBgcolor() {
    // this.aboutActive = !this.aboutActive
    //this.homeActive = !this.homeActive
  }

  redirectToWebsite() {
    let URL = environment.website_domain
    let redirectingURL = `https://` + URL + `.com/home`
    window.open(redirectingURL, "_blank");
  }

  onLogout() {
    this.authService.onLogout();
    this.authService.emitLogoutEventTrigger({ logout: true });
  }

  signin() {
    let URL = environment.website_domain
    let redirectingURL = `https://` + URL + `.com/login`
    window.open(redirectingURL, "_blank");
  }

  signup() {
    let URL = environment.website_domain
    let redirectingURL = `https://` + URL + `.com/individualsignup`
    window.open(redirectingURL, "_blank");
  }

}
