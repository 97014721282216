import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import Swal, { SweetAlertResult } from 'sweetalert2';




@Injectable()
export class HandleErrors {
  constructor(
    private route: ActivatedRoute, private authService: AuthenticationService, private toastMessage: ToastMessage,
    private router: Router) {
  }
  HandleError(error: any, modalReference?: any): void {
    var Message = "";
    if (error.error != undefined && error.error.Message != undefined && error.error.Message != null && error.error.Message != "") {
      Message = error.error.Message
    }
    else if (error.error != undefined && error.error.message != undefined && error.error.message != null && error.error.message != "") {
      Message = error.error.message
    }

    else if (error != null && error.response != undefined && error.response != null && error.response != '') {
      var errordata = JSON.parse(error.response);
      Message = errordata.message
    }
    else {
      Message = "Error Occured, Please try again"
    }
    if (error.status == 400 || error.status == 500) { // BadRequest OR Internal Server Error
      this.toastMessage.Error(Message);
    }
    else if (error.status == 405) {
      this.toastMessage.Error(Message);
    }


    else if (error.status == 401) { // Token Expired Or Unauthorized
      //this.authService.onLogout();
      this.toastMessage.Error(Message);
    }
    else if (error.status == 0 && !navigator.onLine) {
      this.toastMessage.Error("No Internet");

    }
    else {
      this.toastMessage.Error(Message);
    }
  }
}

@Injectable()
export class ToastMessage {
  constructor() { }
  Success(Message: string, timer?: number) {
    Swal.fire({
      title: 'Success!',
      icon: 'success',
      text: Message,
      timer: timer ? timer : 2000,
      toast: true,
      position: 'top',
      showConfirmButton: false
    })

  }


  Error(Message: string, timer?: number) {

    Swal.fire({
      title: 'Error!',
      text: Message,
      timer: timer ? timer : 5000,
      icon: 'error',
      toast: true,
      position: 'top',
      showConfirmButton: false
    });
    
  }
  ConfirmationAlert(title: string, text: string, confirmbuttontext?: string): Promise<SweetAlertResult> {
    return Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmbuttontext != undefined && confirmbuttontext != null && confirmbuttontext != '' ? confirmbuttontext : 'Yes, delete it!',
    //  allowEscapeKey: false,
      //allowOutsideClick :false
    }).then((result) => {
      //if (result.value) {
      //  return result;
      //}
      //return null;
      return result;
    });
  }

  
}

